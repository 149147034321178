import React, { Component } from 'react';
import { toast } from 'react-toastify';
import LoginForm from './loginForm';

class AccountLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  loginFailed = (error) => {
    toast.error(error || 'Oops! Something went wrong. Try again later.');
  }

  loginSuccess = () => {
    if (env.E_DROP_ONLY === 'true') {
      window.location.pathname = '/account/collection';
    } else {
      window.location.pathname = '/';
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div className="account-container__outer">
        <div className="account-container__inner">
          <div className="account-box -center -margin-top">
            <LoginForm
              onSuccess={() => this.loginSuccess()}
              onFail={(error) => this.loginFailed(error)} />
          </div>
        </div>
      </div>
    );
  }
}

export default AccountLogin;
