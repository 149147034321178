import React, { useState, useEffect } from 'react';
import { useMetaMask } from 'metamask-react';
import { polygonParams, polygonTest } from '../../config/polygon';

const MetamaskWallet = ({
  type, onConnect, onInitAddress, mode,
}) => {
  const {
    status, connect, account, chainId, ethereum, switchChain, addChain,
  } = useMetaMask();
  const [walletStatus, setWalletStatus] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);

  const polygonNetworkParams = env.BLOCKCHAIN_ENVIRONMENT === 'test' ? polygonTest : polygonParams;

  const connectToEthereum = () => {
    setIsConnecting(true);
    connect();
  };

  useEffect(() => {
    setWalletStatus(status);
    console.log('walletStatus:');
    const user = {
      addr: account,
    };
    if (status === 'connected' && isConnecting) {
      setIsConnecting(false);
      onConnect(user);
    } else if (status === 'connected') {
      console.log('initialized component with a wallet address');
      if (onInitAddress) {
        onInitAddress(user);
      }
    } else if (status === 'notConnected') {
      console.log('Wallet was disconnected');
      if (onInitAddress) {
        onInitAddress(user);
      }
    }
    console.log(status);
  }, [status]);

  //   if (status === 'notConnected') return <a href="#" onClick={connect} className="button-metamask" alt="Connect Metamask wallet"><img src="https://ipfs.ethosnft.com/ethos/icon-metamask.png" alt="" /></a>;
  //   if (status === 'connecting') return <a className="button-metamask"><img src="https://ipfs.ethosnft.com/ethos/icon-metamask.png" alt="" /></a>;
  //   if (status === 'connected') return <a className="button-metamask"><img src="https://ipfs.ethosnft.com/ethos/icon-metamask-connected.png" alt="" /></a>;

  return (
    <div>
      {type === 'nav'
        ? (
          <div>
            {walletStatus === 'connected'
              ? <a className="button-metamask" alt="Connect Metamask wallet"><img src="https://ipfs.ethosnft.com/ethos/icon-metamask-connected.png" alt="" /></a>
              : <a href="#" onClick={() => connectToEthereum()} className="button-metamask" alt="Connect Metamask wallet"><img src="https://ipfs.ethosnft.com/ethos/icon-metamask.png" alt="" /></a>}
          </div>
        )
        : (
          <div className="form-cta">
            {mode !== 'transfer'
            && (
              <div>
                {walletStatus === 'connected'
                    && (
                    <div>
                      Your connected Metamask wallet:
                      {' '}
                      {account}
                      {/* <br />
                      Chain:
                      {' '}
                      {chainId}
                      <br />
                      <button type="button" onClick={() => addChain(polygonNetworkParams)}>Switch to Polygon</button> */}
                    </div>
                    )}
              </div>
            )}
            {walletStatus === 'notConnected'
                && <button type="button" onClick={() => connectToEthereum()}>Connect your Metamask wallet</button>}
            {walletStatus === 'unavailable'
                && <a className="button" href="https://metamask.io/" target="_blank" rel="noreferrer">Install Metamask</a>}
          </div>
        )}
    </div>
  );
};
export default MetamaskWallet;
