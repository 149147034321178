/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import UtilityManager from '../../managers/Utility';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PreviewBenefits = ({ asset, benefits }) => {
  const [previewBenefits, setBenefits] = useState([]);

  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div
        className="storefront-benefits"
        style={{
          backgroundImage: 'url(' + env.HERO_BACKGROUND + ')',
        }}>
      <div className="storefront-benefits__overlay"></div>
      <div className="storefront-benefits__inner">
        <div className="storefront-benefits__title">Member’s benefits and perks</div>
        <div className="storefront-benefits__row">
          <Slider {...settings}>
            {benefits.map((perk, i) => (
              <div key={i}>
                <div
                  className="storefront-benefits__item"
                  style={{
                    backgroundImage: 'url(' + UtilityManager.getUtilityThumbnail(perk) + ')',
                  }}>
                  <div className="storefront-benefits__item--title">{perk.name}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default PreviewBenefits;
