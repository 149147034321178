import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Throbber from '../throbber';
import ContestManager from '../../managers/Contests';
import AccountManager from '../../managers/Account';

const ContestEntry = ({ contestId, onCloseModal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [contest, setContest] = useState(null);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasEntered, setHasEntered] = useState(false);

  const getContest = async (contestId) => {
    const contest = await ContestManager.getContest(AccountManager.getToken(), contestId);
    console.log('=== CONTEST DETAILS ===');
    console.log(contest);
    if (contest && contest.success) {
      setContest(contest.contest);
      setIsLoading(false);
    } else {
      toast.error('Error getting contest details');
    }
  };

  const enterContest = async (contestId) => {
    setIsSubmitting(true);
    const contest = await ContestManager.enterContest(AccountManager.getToken(), contestId);
    if (contest && contest.success) {
      toast.success('Successfully entered the contest!');
      localStorage.setItem(`ETHOS_CONTEST_${contestId}`, 'true');
      setHasEntered(true);
    } else {
      toast.error(contest && contest.message ? contest.message : 'Error entering contest!');
    }
    setIsSubmitting(false);
  };

  const handleCheckboxChange = (e) => {
    let isChecked = false;
    if (e.target.checked) {
      isChecked = true;
    } else {
      isChecked = false;
    }
    setAgreeTerms(isChecked);
  };

  useEffect(() => {
    if (contestId) {
      // go get the contest details
      getContest(contestId);
      if (localStorage.getItem(`ETHOS_CONTEST_${contestId}`)) {
        setHasEntered(true);
      }
    }
  }, [contestId]);

  return (
    <div>
      {isLoading ? (
        <Throbber throbberText="Loading contest..." />
      )
        : (
          <div className="contest-modal">
            {contest && (
            <>
              <div className="contest-modal__header">{contest.title}</div>
              <div className="contest-modal__prize-description">
                {contest.prizeNumber && `${contest.prizeNumber} Winner${contest.prizeNumber > 1 ? 's' : ''} - `}
                {contest.prizeDescription}
              </div>
              <div className="contest-modal__image">
                {isSubmitting && (
                  <Throbber throbberText="Entering contest..." />
                )}
                {hasEntered ? (
                  <div className="contest-modal__success">
                    <img src="https://assets.heyethos.com/ethos/v2/ui/icon-success.png" alt="Contest success" />
                    You have been entered into the contest!
                  </div>
                ) : (
                  <img src={contest.thumbnailImage} alt="Contest thumbnail" />
                )}
              </div>
              <div className="contest-modal__description">{contest.description}</div>
              {hasEntered ? (
                <div className="contest-modal__terms">
                    &nbsp;
                  <div className="contest-modal__cta">
                    <button
                        type="button"
                        className="button-collection"
                        onClick={() => onCloseModal()}>
                      Done
                    </button>
                  </div>
                </div>
              ) : (
                <div className="contest-modal__terms">
                  <input
                    id="registerAcceptTerms"
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)} />
                  <label className="label-inline" htmlFor="registerAcceptTerms">
                    By entering, I agree to the
                    {' '}
                    <a href="https://www.ethosnft.com/terms" target="_blank" rel="noreferrer">Terms &amp; Conditions</a>
                    .
                  </label>
                  <div className="contest-modal__cta">
                    <button
                        type="button"
                        className="button-collection"
                        disabled={!agreeTerms || isSubmitting}
                        onClick={() => enterContest(contest.contestId)}>
                      Enter
                    </button>
                  </div>
                </div>
              )}
            </>
            )}
          </div>
        )}
    </div>
  );
};
export default ContestEntry;
