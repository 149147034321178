import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';
import CardOnFile from './cardOnFile';

const API_KEY = env.PAYSAFE_API_KEY;
const options = {
  currencyCode: 'USD',
  accounts: {
    // regular
    // default: 1002591400,
    // threeDs
    default: parseInt(env.PAYSAFE_ACCOUNT_NUMBER, 10),
  },
  environment: env.PAYSAFE_ENVIRONMENT,
  // environment: 'TEST',
  fields: {
    cardNumber: {
      selector: '#cardNumber',
      placeholder: '****************',
      separator: ' ',
    },
    cvv: {
      selector: '#cvv',
      placeholder: '****',
      mask: false,
    },
    expiryDate: {
      selector: '#expiryDate',
      placeholder: '**/**',
    },
  },
  style: {
    input: {
      'font-family': 'robotoregular,Helvetica,Arial,sans-serif',
      'font-weight': 'normal',
      'font-size': '16px',
    },

    '.valid:focus': {
      color: 'green',
    },
  },
};
let ethosInstance;

class PaySafeCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      loadingText: 'Initializing payment form. Stand by...',
    };
  }

  saveCard = async (token) => {
    const { onCardEntry } = this.props;
    const payload = {
      id: token,
    };
    const savedCard = await AccountManager.setPaymentMethod(AccountManager.getToken(), payload);
    if (savedCard && savedCard.success) {
      onCardEntry(token);
    } else {
      toast.error(savedCard.message || 'Oops. Something went wrong. Please try again later.');
      this.setState({
        isLoading: false,
      });
    }
  }

  initPaysafe = async () => {
    const {
      cvvOnly, onCardEntry, total, accountData, paymentHandleToken, singleUseCustomerToken,
    } = this.props;
    const accountInfo = await AccountManager.get(AccountManager.getToken(), true);
    const that = this;
    const accountDetails = accountData;
    if (!accountInfo) {
      toast.error('No account info found.');
    }
    paysafe.fields.setup(API_KEY, options).then((instance) => {
      ethosInstance = instance;
      return instance.show();
    }).then((paymentMethods) => {
      if (paymentMethods.card && !paymentMethods.card.error) {
        that.setState({
          isLoading: false,
        });
        document.getElementById('addCard').addEventListener('click', (event) => {
          that.setState({
            loadingText: 'Saving credit card. Stand by...',
            isLoading: true,
          });

          const tokenizationOptions = {
            amount: total,
            merchantRefNum: 'ethos-ref-num-' + new Date().getTime(),
            // transactionType: 'PAYMENT',
            transactionType: 'VERIFICATION',
            paymentType: 'CARD',
            // New fields required for saved cards
            // paymentTokenFrom: document.getElementById('customerToken').value,
            // singleUseCustomerToken: document.getElementById('savedCardToken').value,
            paymentTokenFrom: paymentHandleToken || null,
            singleUseCustomerToken: singleUseCustomerToken || null,
            openAs: 'IFRAME',
            threeDs: {
              merchantUrl: env.PAYSAFE_MERCHANT_URL + '/checkout/v2/index.html#/desktop',
              // merchantUrl: 'https://api.paysafe.com/checkout/v2/index.html#/desktop',
              useThreeDSecureVersion2: true,
              authenticationPurpose: 'PAYMENT_TRANSACTION',
              deviceChannel: 'BROWSER',
              messageCategory: 'PAYMENT',
            },
            customerDetails: {
              // holderName: accountDetails.firstName + ' ' + accountDetails.lastName,
              billingDetails: {
                country: accountDetails.billingInfo.countryCode,
                zip: accountDetails.billingInfo.zip,
                street: accountDetails.billingInfo.address,
                city: accountDetails.billingInfo.city,
                state: accountDetails.billingInfo.stateCode,
              },
              profile: {
                firstName: accountDetails.firstName,
                lastName: accountDetails.lastName,
                email: accountDetails.email,
              },
            },
          };
          ethosInstance.tokenize(tokenizationOptions).then((result) => {
            console.log('=== Tokenization Options ===');
            console.log(tokenizationOptions);
            if (cvvOnly) {
              onCardEntry(result.token);
            } else {
              that.saveCard(result.token);
            }
          }).catch((error) => {
            that.setState({
              isLoading: false,
            });
            console.log(error.message);
            toast.error(error.message);
          });
        });
      }
    });
  }

  componentDidMount() {
    const { accountData, cvvOnly, mode } = this.props;
    if (cvvOnly) {
      options.fields.cardNumber.optional = true;
      options.fields.expiryDate.optional = true;
    }

    this.initPaysafe();
  }

  render() {
    const { isLoading, loadingText } = this.state;
    const {
      onSuccess, accountData, cvvOnly, mode,
    } = this.props;
    return (
      <div>
        {isLoading
          && <Throbber throbberText={loadingText} />}
        {!cvvOnly && !mode
        && (
        <div className="account-heading">
          Enter Your Credit Card
        </div>
        )}
        {/* <div className="form-section">
          <div className="label-block">Customer token</div>
          <input id="customerToken" className="inputField" />
          <p></p>
        </div>
        <div className="form-section">
          <div className="label-block">Payment token from</div>
          <input id="savedCardToken" className="inputField" />
          <p></p>
        </div> */}

        <div>
          {cvvOnly
          && (
          <div>
            <CardOnFile
              cardDetails={AccountManager.getSelectedCard(accountData.paymentMethod)} />
            Please confirm the CVC for this card to complete your purchase.
          </div>
          )}
          <div className={cvvOnly ? 'hidden' : 'block'}>
            <div className="form-section">
              <div className="label-block">Card number:</div>
              <div id="cardNumber" className="inputField"></div>
              <p></p>
            </div>
            <div className="form-section">
              <div className="label-block">Expiry:</div>
              <div id="expiryDate" className="inputField"></div>
              <p></p>
            </div>
          </div>
        </div>
          
        <div className="form-section">
          <div className="label-block">CVC:</div>
          <div id="cvv" className={cvvOnly ? 'inputField -short' : 'inputField'}></div>
          <p></p>
        </div>
        <div className="form-cta">
          {cvvOnly
            ? <button id="addCard" type="button">Confirm card and purchase</button>
            : <button id="addCard" type="button">Add Credit Card</button>}
        </div>
      </div>
    );
  }
}

PaySafeCardForm.propTypes = {
  onCardEntry: PropTypes.string,
  accountData: PropTypes.string,
  mode: PropTypes.string,
  total: PropTypes.string,
  cvvOnly: PropTypes.bool,
  paymentHandleToken: PropTypes.string,
  singleUseCustomerToken: PropTypes.string,
};

export default PaySafeCardForm;
