import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Throbber from '../throbber';
import SimpleModal from '../modal/modal';
import Slider from '../slider';
import LoginForm from '../account/loginForm';

const EDropLanding = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [previewData, setPreviewData] = useState(null);
  const [emailExists, setEmailExists] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [loginEmailAddress, setLoginEmailAddress] = useState('');

  const scrollToContent = () => {
    const content = document.getElementById('startContent');
    if (content) {
      window.scrollTo({
        top: content.offsetTop - 90,
        behavior: 'smooth',
      });
    }
  };

  const setHeroHeight = () => {
    const hero = document.getElementById('storefrontHero');
    if (hero) {
      hero.style.height = window.innerHeight + 'px';
    }
  };

  const loginSuccess = async () => {
    const successUrl = window.location.href + '#login=success';
    setShowSlider(false);
    if (env.E_DROP_ONLY === 'true') {
      window.location.href = '/account/collection';
    } else {
      window.location.href = successUrl;
      window.location.reload();
    }
  };

  const loginFailed = (error) => {
    toast.error(error || 'Oops! Something went wrong. Try again later.');
  };

  const openLoginModal = (e) => {
    e.preventDefault();
    setShowModal(false);
    // alert('hello');
    setLoginEmailAddress(emailAddress);
    setShowSlider(true);
  };

  useEffect(() => {
    // on mount
    setHeroHeight();
    window.addEventListener('resize', setHeroHeight);
  }, []);

  useEffect(() => {
    if (previewData) {
      handleStickySignup();
    }
  }, [previewData]);

  return (
    <div id="storefrontHero" className="storefront__hero">
      <Slider isOpen={showSlider} onBeforeClose={() => setShowSlider(false)}>
        <LoginForm
          prepopulateEmail={loginEmailAddress}
          onSuccess={() => loginSuccess()}
          onFail={(error) => loginFailed(error)}
          onRegisterButton={() => setShowSlider(false)} />
      </Slider>
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        {isLoading
          && <Throbber throbberText="Claiming..." />}
        {!isLoading
        && (
        <div>
          {emailExists
            ? (
              <div>
                <div className="account-subheading -slim-padding">
                  An account with this email already exists. Log in now to join!&nbsp;
                </div>
                <div className="form-cta">
                  <a
                    href="#"
                    onClick={(e) => openLoginModal(e)}
                    className="button">
                    Log in
                  </a>
                  {/* <Link
                    to="/login"
                    className="button">
                    Log in
                  </Link> */}
                </div>
              </div>
            )
            : (
              <div>Loader</div>
            )}
        </div>
        )}
      </SimpleModal>
      <div className="storefront__hero--inner">
        {env.HERO_ACCENT_BACKGROUND
        && <img className="storefront__hero--accent" src={env.HERO_ACCENT_BACKGROUND} alt={env.SITE_NAME} />}
        <div className="storefront__hero--content">
          <div className="storefront__hero--asset">
            <img src="https://ipfs.ethosnft.com/ethos/asset-placeholder.png" alt="Numinus Alumni Network" />
            <div className="storefront__hero--asset__shadow"></div>
          </div>
          <div className="storefront__hero--logo">
            <img src={env.LARGE_LOGO} alt={env.SITE_NAME} />
          </div>
          <div className="storefront__hero--title">
            Your microsite is ready!
          </div>

          <div className="storefront__hero--description">Your microsite is ready. You next steps is setting up your loyalty program!</div>
          
        </div>
      </div>
      
      <div className="hero--more-arrow" onClick={() => scrollToContent()}>
        <div className="more-arrow-bounce">
          Learn more
          <img src="https://ipfs.ethosnft.com/ethos/v2/ui/icon-down-arrow.svg" alt="Warning" />
        </div>
      </div>
    </div>
  );
};
export default EDropLanding;
