import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import PubSub from 'pubsub-js';
import PropTypes from 'prop-types';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';
import CardForm from './CardForm';
import PaySafeCardForm from './PaySafeCardForm';
import CardOnFile from './cardOnFile';

// const stripePromise = loadStripe(env.STRIPE_KEY);

class BillingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billingAddress: '',
      billingCity: '',
      billingCode: '',
      country: '',
      region: '',
      whiteListed: ['US', 'CA'],
      priorityOptions: ['US', 'CA'],
      isLoading: true,
      stripeOptions: {},
      tab: 'billing',
      hasBillingInfo: false,
      cardDetails: null,
      updateCard: false,
    };
    PubSub.subscribe('card_updated', this.getCardDetails);
  }

  getCardDetails = async () => {
    let cardDetails;
    const hasCardOnFile = await AccountManager.getPaymentMethod(AccountManager.getToken());
    if (hasCardOnFile && hasCardOnFile.success && hasCardOnFile.card) {
      cardDetails = AccountManager.getSelectedCard(hasCardOnFile.card);
      this.setState({
        cardDetails,
      });
    }
  }

  async componentDidMount() {
    // get billingInfo if it exists
    const { mode } = this.props;
    await this.getCardDetails();
    
    const accountData = await AccountManager.get(AccountManager.getToken());
    if (accountData && accountData.billingInfo && accountData.billingInfo.address) {
      this.setState({
        accountData,
        billingAddress: accountData.billingInfo.address || '',
        billingCity: accountData.billingInfo.city || '',
        billingCode: accountData.billingInfo.zip || '',
        country: accountData.billingInfo.country || '',
        region: accountData.billingInfo.state || '',
        isLoading: false,
        hasBillingInfo: true,
        tab: mode || 'billing',
      });
    } else {
      this.setState({
        isLoading: false,
        hasBillingInfo: false,
      });
    }
  }

  onEnterKey = (e) => {
    if (e.keyCode === 13) {
      // this.validateForm();
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  selectCountry = (val) => {
    this.setState({ country: val });
  }

  selectRegion = (val) => {
    this.setState({ region: val });
  }

  validateForm = () => {
    const { billingAddress } = this.state;
    const { billingCity } = this.state;
    const { billingCode } = this.state;
    const { country } = this.state;
    const { region } = this.state;
    if (billingAddress === ''
      || billingCity === ''
      || billingCode === ''
      || country === ''
      || region === '') {
      toast.error('Please fill out all fields.');
      return false;
    }
    return true;
  }

  updateDetails = async (e) => {
    const { onSuccess } = this.props;
    const {
      billingAddress, billingCity, billingCode, country, region,
    } = this.state;

    e.preventDefault();
    let payload = {};
    payload = {
      address: billingAddress,
      city: billingCity,
      state: region,
      zip: billingCode,
      country,
    };
    if (this.validateForm()) {
      this.setState({
        isLoading: true,
      });
      const billingChanges = await AccountManager.billing(env.SERVER_URL + 'account/billing', payload, AccountManager.getToken());
      const accountData = await AccountManager.get(AccountManager.getToken(), true);
      if (billingChanges && accountData) {
        this.setState({
          accountData,
          isLoading: false,
          hasBillingInfo: true,
          tab: accountData && accountData.billingInfo && accountData.paymentMethod ? 'billing' : 'card',
        });
        onSuccess();
        toast.success('Billing details successfully updated!');
      } else {
        toast.error('Unable to save billing changes. Please try again later.');
      }
    }
  }

  toggleTab = (tab, e) => {
    const currentTab = tab;
    e.preventDefault();
    this.setState({
      tab: currentTab,
      updateCard: false,
    });
  }

  changeCreditCard = (e) => {
    e.preventDefault();
    this.setState({
      updateCard: true,
    });
  }

  enteredCard = async () => {
    const { onCardSuccess, mode } = this.props;
    this.setState({
      isLoading: true,
    });
    await this.getCardDetails();
    this.setState({
      updateCard: false,
      isLoading: mode === 'card',
    });

    onCardSuccess();
  }

  render() {
    const {
      accountData, updateCard, tab, hasBillingInfo, stripeOptions, billingAddress, billingCity, billingCode, isLoading, country, region, priorityOptions, cardDetails,
    } = this.state;
    const { onCardSuccess, mode } = this.props;
    return (
      <div>
        {hasBillingInfo
          && (
          <div className="tabs-container -no-box">
            <div className={tab === 'billing' ? 'tabs-item -current' : 'tabs-item'}>
              <a onClick={(e) => this.toggleTab('billing', e)} href="#">Billing Address</a>
            </div>
            <div className={tab === 'card' ? 'tabs-item -current' : 'tabs-item'}>
              <a onClick={(e) => this.toggleTab('card', e)} href="#">Credit Card Details</a>
            </div>
          </div>
          )}

        {tab === 'billing'
        && (
        <div>
          {isLoading
          && <Throbber throbberText="Updating your billing details! Please stand by..." />}
          <h2 className="account-heading__h2">
            Add Billing Address
          </h2>
          <div className="account-subheading">
            Add or update your billing address here
          </div>
          <div className="form-container">
            <div className="form-section">
              <label className="label-block" htmlFor="billingAddress">Street address</label>
              <input
                className="input-block"
                id="billingAddress"
                type="text"
                value={billingAddress}
                onChange={(e) => this.handleInputChange(e)}
                onKeyUp={(e) => this.onEnterKey(e)} />
            </div>
            <div className="form-section">
              <label className="label-block" htmlFor="billingCity">City</label>
              <input
                className="input-block"
                id="billingCity"
                type="text"
                value={billingCity}
                onChange={(e) => this.handleInputChange(e)}
                onKeyUp={(e) => this.onEnterKey(e)} />
            </div>
            <div className="form-section">
              <label className="label-block" htmlFor="billingCode">Zip Code</label>
              <input
                className="input-block"
                id="billingCode"
                type="text"
                value={billingCode}
                onChange={(e) => this.handleInputChange(e)}
                onKeyUp={(e) => this.onEnterKey(e)} />
            </div>
            <div className="form-section">
              <div className="label-block">Country:</div>
              <CountryDropdown
                priorityOptions={priorityOptions}
                defaultOptionLabel=""
                value={country}
                classes="input-block"
                onChange={(val) => this.selectCountry(val)} />
            </div>
            {country !== ''
            && (
            <div className="form-section">
              <div className="label-block">State:</div>
              <RegionDropdown
                country={country}
                defaultOptionLabel=""
                value={region}
                classes="input-block"
                onChange={(val) => this.selectRegion(val)} />
            </div>
            )}
            <div className="form-cta">
              <button
                className="button"
                type="submit"
                onClick={(e) => this.updateDetails(e)}>
                Save Billing Address
              </button>
            </div>
          </div>
        </div>
        )}

        {tab === 'card'
        && (
        <div>
          {isLoading
          && <Throbber throbberText="Updating your billing details! Please stand by..." />}
          <div className="account-heading__h2">
            Card Details
          </div>
          <div className="account-subheading">
            View and update your credit card details here
          </div>
          <div>
            {cardDetails && !updateCard
              ? (
                <div>
                  <CardOnFile
                    cardDetails={cardDetails} />
                  <div className="form-cta">
                    <button
                          className="button"
                          type="submit"
                          onClick={(e) => this.changeCreditCard(e)}>
                      Change Credit Card
                    </button>
                  </div>
                </div>
              )
              : (
                <div className="form-container">
                  {/* {!isLoading
                  && (
                  <Elements stripe={stripePromise} options={stripeOptions}>
                    <CardForm onSuccess={onCardSuccess} />
                  </Elements>
                  )} */}
                  <PaySafeCardForm
                    mode={mode}
                    onCardEntry={() => this.enteredCard()}
                    accountData={accountData}
                    total={0} />
                </div>
              )}
          </div>
        </div>
        )}
      </div>
    );
  }
}

BillingForm.propTypes = {
  onSuccess: PropTypes.func,
  onCardSuccess: PropTypes.func,
  mode: PropTypes.string,
};

export default BillingForm;
