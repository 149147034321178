import moment from 'moment-mini';
import Ajax from './Ajax';

/**
* Utility helper for Account functions
*/

class Creator {
  constructor() {
    this.creatorData = null;
    this.assetData = null;
    this.dropData = null;
    this.creatorId = env.CREATOR_ID;
    this.assetId = env.ASSET_ID;
    this.assetId2 = env.ASSET2_ID;
    this.dropId = env.DROP_ID;
    this.dropId2 = env.DROP2_ID;
    this.apiUrl = env.SERVER_URL;
  }

  createRisk = (payloadData, token) => {
    const payload = {
      assetPrice: payloadData.purchase,
      gasFee: payloadData.gas,
      tax: payloadData.tax,
      dropId: this.dropId,
      cartToken: payloadData.cartToken,
    };
    const that = this;
    return Ajax.post(that.apiUrl + 'risk/create', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  createDecision = (payloadData, token) => {
    const payload = {
      assetPrice: payloadData.price,
      gasFee: payloadData.gasFee,
      tax: payloadData.tax,
      dropId: this.dropId,
      cartToken: payloadData.cartToken,
    };
    const that = this;
    return Ajax.post(that.apiUrl + 'risk/decision', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  confirmPurchase = (payload, token) => {
    const that = this;
    return Ajax.post(that.apiUrl + 'payment/pay', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  generateGatedCommerceUrl = (gatedCommerceId, nftId, token) => {
    const that = this;
    return Ajax.get(that.apiUrl + 'utility/draftorder?gatedCommerceId=' + gatedCommerceId + '&nftId=' + nftId, token)
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  getSingleDropV2 = (dropId) => {
    // this needs to return a dropData object that contains a drop and asset property
    const that = this;
    const dropData = {
      drop: null,
      asset: null,
    };
    return Ajax.get(that.apiUrl + 'drop/v2/' + dropId)
      .then((data) => {
        if (data.asset) {
          dropData.asset = data.asset;
          dropData.asset.assetVariationId = data.asset.assetId;
        }
        if (data.drop.dropId === dropId) {
          dropData.drop = data.drop;
        }
        if (data.assetVariations && data.assetVariations.length > 0) {
          dropData.asset.assetVariationId = data.assetVariations[0].assetVariationId;
        }
        return dropData;
      })
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getSingleDrop = (dropId) => {
    // this needs to return a dropData object that contains a drop and asset property
    const that = this;
    const dropData = {
      drop: null,
      asset: null,
    };
    return Ajax.get(that.apiUrl + 'drop/v2/' + dropId)
      .then((data) => {
        if (data.assets) {
          dropData.asset = data.assets[0];
        }
        console.log('=== dropData.asset  ===');
        console.log(data);
        dropData.asset.drops.forEach((drop) => {
          if (drop.dropId === dropId) {
            dropData.drop = drop;
          }
        });
        return dropData;
      })
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  isCountdownOn = () => {
    if (env.E_DROP_ONLY === 'true') {
      return false;
    }
    const currentDate = moment().unix() * 1000;
    if (this.creatorData.drop && this.creatorData.drop.countDown) {
      return moment(this.creatorData.drop.countDown.countDownDate).unix() * 1000 > currentDate;
    }
    return false;
  }

  getCreatorData = () => {
    const dropUrl = this.apiUrl + 'creator/' + this.creatorId;
    return Ajax.get(dropUrl)
      .then((data) => data)
      .catch((error) => {
      // that.clear();
        throw error;
      });
  }

  getDrop = () => {
    const that = this;
    let assetData = null;
    let assetData2 = null;
    let dropData = null;
    let dropData2 = null;
    if (this.creatorData !== null) {
      return this.creatorData;
    }
    // const dropUrl = that.apiUrl + 'drop/' + this.dropId;
    const dropUrl = that.apiUrl + 'creator/' + this.creatorId;

    return Ajax.get(dropUrl)
      .then((data) => {
        data.assets.forEach((asset) => {
          if (asset.assetId === that.assetId) {
            assetData = asset;
          }
        });
        if (that.assetId2) {
          data.assets.forEach((asset) => {
            if (asset.assetId === that.assetId2) {
              assetData2 = asset;
            }
          });
        }
        assetData.drops.forEach((drop) => {
          if (drop.dropId === that.dropId) {
            dropData = drop;
          }
        });
        if (that.dropId2) {
          assetData2.drops.forEach((drop) => {
            if (drop.dropId === that.dropId2) {
              dropData2 = drop;
            }
          });
        }
        const creatorData = {
          creator: data,
          asset: assetData,
          asset2: assetData2,
          drop: dropData,
          drop2: dropData2,
        };
        that.creatorData = creatorData;
        return creatorData;
      })
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }
}

export default new Creator();
