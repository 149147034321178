import React, { Component } from 'react';
import SetupLanding from '../components/setup/setupLanding';
import HowItWorks from '../components/edrop/howItWorks';
import AssetFAQ from '../components/collection/assetFAQ';
import AccountManager from '../managers/Account';
import TransferChecker from '../components/transfer/transferChecker';

class Setup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorData: {},
      transferToken: null,
      transferCookie: null,
      asset: null,
      showModal: true,
      benefits: [],
    };
  }

  componentDidMount() {
    // document.title = env.CREATOR_SUBDOMAIN === 'account' ? 'ethos' : env.SITE_NAME + ' | Join the ' + env.DIGITAL_ASSET_TYPE;
    // if (getParameterByName('id', window.location.href)) {
    //   // protec against duplicate tokens
    //   Object.entries(sessionStorage).map((obj) => {
    //     const key = obj[0];
    //     if (key.includes('NFT_TRANSFER')) {
    //       sessionStorage.removeItem(key);
    //     }
    //     return true;
    //   });
    //   // force the one in the URL to be in memory
    //   this.setState({
    //     transferCookie: 'NFT_TRANSFER_' + getParameterByName('id', window.location.href),
    //     transferToken: getParameterByName('id', window.location.href),
    //   });
    //   sessionStorage.setItem('NFT_TRANSFER_' + getParameterByName('id', window.location.href), getParameterByName('id', window.location.href));
    // } else {
    //   window.location.href = '/';
    // }
  }

  componentWillUnmount() {
    document.title = env.SITE_NAME;
  }

  render() {
    return (
      <div>
        <SetupLanding />
        
        <HowItWorks />

        <div className="storefront-faq">
          <div className="storefront-faq--title">FAQ</div>
          <AssetFAQ noHeader />
        </div>
      </div>
    );
  }
}

export default Setup;
