import React, { Component } from 'react';
import { toast } from 'react-toastify';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';

const API_KEY = env.PAYSAFE_API_KEY;
const options = {
  // You must provide currencyCode to the Paysafe JS SDK to enable the Payment API integration
  currencyCode: 'USD',
  // select the Paysafe test / sandbox environment
  environment: 'TEST',
  // Provide a cards merchant toaccount if you have more than one configured for that same API key
  //   accounts: {
  //   default: 0000000000,
  // },
  // set the CSS selectors to identify the payment field divs above
  // set the placeholder text to display in these fields
  fields: {
    cardNumber: {
      selector: '#cardNumber',
      placeholder: 'Card number',
      separator: ' ',
    },
    expiryDate: {
      selector: '#expiryDate',
      placeholder: 'Expiry date',
    },
    cvv: {
      selector: '#cvv',
      placeholder: 'CVV',
      optional: false,
    },
  },
};
let ethosInstance;

class PaySafeCardEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      loadingText: 'Processing payment. Stand by...',
    };
  }

  saveCard = async (token) => {
    const { onCardEntry } = this.props;
    const payload = {
      id: token,
    };
    const savedCard = await AccountManager.setPaymentMethod(AccountManager.getToken(), payload);
    if (savedCard && savedCard.success) {
      onCardEntry(token);
    } else {
      toast.error(savedCard.message || 'Oops. Something went wrong. Please try again later.');
      this.setState({
        isLoading: false,
      });
    }
  }

  initPaysafe = async () => {
    const { total } = this.props;
    const accountInfo = await AccountManager.get(AccountManager.getToken(), true);
    const that = this;
    if (!accountInfo) {
      toast.error('No account info found.');
    }
    paysafe.fields.setup(API_KEY, options).then((instance) => {
      console.log('Setup instance completed.');
      ethosInstance = instance;
      return instance.show();
    }).then((paymentMethods) => {
      if (paymentMethods.card && !paymentMethods.card.error) {
        document.getElementById('addCard').addEventListener('click', (event) => {
          that.setState({
            isLoading: true,
          });
          const tokenizationOptions = {
            amount: total,
            transactionType: 'PAYMENT',
            paymentType: 'CARD',
            merchantRefNum: 'merchant-ref-num-' + new Date().getTime(),
            customerDetails: {
              billingDetails: {
                country: accountInfo.billingInfo.countryCode,
                zip: accountInfo.billingInfo.zip,
              },
            },
          };
          ethosInstance.tokenize(tokenizationOptions).then((result) => {
            // write the Payment token value to the browser console
            console.log(result.token);
            that.saveCard(result.token);
          }).catch((error) => {
            // display the tokenization error in dialog window
            that.setState({
              isLoading: false,
            });
            console.log(error.message);
            toast.error(error.message);
            // console.log(JSON.stringify(error));
          });
        });
      }
    });
  }

  componentDidMount() {
    this.initPaysafe();
  }

  render() {
    const { isLoading, loadingText } = this.state;
    return (
      <div>
        {isLoading
          && <Throbber throbberText={loadingText} />}
        <div className="account-heading">
          Enter Your Credit Card
        </div>
        <div className="form-section">
          <div className="label-block">Card number:</div>
          <div id="cardNumber" className="inputField"></div>
          <p></p>
        </div>
        <div className="form-section">
          <div className="label-block">Expiry:</div>
          <div id="expiryDate" className="inputField"></div>
          <p></p>
        </div>
        <div className="form-section">
          <div className="label-block">CVC:</div>
          <div id="cvv" className="inputField"></div>
          <p></p>
        </div>
        <div className="form-cta">
          <button id="addCard" type="button">Add Credit Card</button>
        </div>
      </div>
    );
  }
}

PaySafeCardEntry.propTypes = {
  onCardEntry: PropTypes.func,
  total: PropTypes.string,
};

export default PaySafeCardEntry;
