import React, { useState } from 'react';

const ClaimState = ({ messageType }) => {
// State using the useState hook
  const [count, setCount] = useState(0);
  const someFunction = () => {
    // hello
  };

  return (
    <div className="storefront__hero--claim-state">
      {messageType && messageType === 'dropOver'
        && (
        <div>
          <div className="icon">
            <img src="https://ipfs.ethosnft.com/ethos/v2/ui/icon-warning.svg" alt="Warning" />
          </div>
          <div className="content">
            <p>Sorry, this asset is no longer available.</p>
          </div>
          <div className="storefront__hero--claim-state__overlay"></div>
        </div>
        )}
      {messageType && messageType === 'claimed'
        && (
        <div>
          <div className="icon">
            <img src="https://ipfs.ethosnft.com/ethos/v2/ui/icon-warning.svg" alt="Warning" />
          </div>
          <div className="content">
            <p>Sorry, access through this link as already been claimed. If you have already claimed access, log in to access it.</p>
          </div>
          <div className="storefront__hero--claim-state__overlay"></div>
        </div>
        )}
    </div>
  );
};
export default ClaimState;
