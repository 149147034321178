import React, { Component } from 'react';
import { toast } from 'react-toastify';
// import PubSub from 'pubsub-js';
import Throbber from '../throbber';
import Account from '../../managers/Account';

const passwordRequirements = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registerPassword: '',
      registerConfirmPassword: '',
      isLoading: false,
      isSuccess: false,
      resetToken: '',
      bearerToken: '',
    };
  }

  validateForm = () => {
    const { registerPassword } = this.state;
    const { registerConfirmPassword } = this.state;
    if (registerPassword === ''
        || registerConfirmPassword === '') {
      toast.error('Please fill out all fields.');
      return false;
    }
    if (registerPassword !== registerConfirmPassword) {
      toast.error('Passwords don\'t match.');
      return false;
    }
    if (!passwordRequirements.test(registerPassword)) {
      toast.error('Passwords must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters');
      return false;
    }
    return true;
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  onEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.validateForm();
    }
  }

  handleCheckboxChange = (e) => {
    let isChecked = false;
    if (e.target.checked) {
      isChecked = true;
    } else {
      isChecked = false;
    }
    this.setState({
      agreeTerms: isChecked,
    });
  }

  resetPassword = async (e) => {
    const {
      registerPassword, registerConfirmPassword, resetToken, bearerToken,
    } = this.state;
    let payload = {};
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({
        isLoading: true,
      });

      payload = {
        password1: registerPassword,
        password2: registerConfirmPassword,
      };

      const changedPassword = await Account.recoverPassword(payload, resetToken, bearerToken);

      if (changedPassword && changedPassword.success) {
        this.setState({
          isLoading: false,
          isSuccess: true,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        toast.error(changedPassword.message || 'Oops! Something went wrong. Please try again later');
      }
    }
  }

  verifyToken = async (token) => {
    this.setState({
      isLoading: true,
    });
    const resetToken = await Account.verifyResetToken(token);
    if (resetToken && resetToken.success) {
      this.setState({
        isLoading: false,
        resetToken: token,
        bearerToken: resetToken.token,
      });
    } else {
      this.setState({
        isLoading: false,
      });
      toast.error(resetToken.message || 'Oops! Something went wrong. Please try again later');
    }
  }

  componentDidMount() {
    document.body.classList.add('-account-background');
    document.body.style.backgroundImage = 'url(' + env.HERO_BACKGROUND + ')';
    const tokenParam = window.location.href.split('/');
    this.verifyToken(tokenParam[tokenParam.length - 1]);
  }

  componentWillUnmount() {
    document.body.classList.remove('-account-background');
    document.body.style.backgroundImage = 'none';
  }

  render() {
    const { registerPassword } = this.state;
    const { registerConfirmPassword } = this.state;
    const { isLoading } = this.state;
    const { isSuccess } = this.state;
    return (
      <div className="account-container__outer">
        <div className="account-container__inner">
          <div className="account-box -center">
            {isLoading
              && <Throbber throbberText="Verifying reset token..." />}
            <div className="account-heading">
              Reset your password
            </div>
            {isSuccess
              ? (
                <div className="form-success">
                  {'You\'ve successfully updated your password!'}
                  <br />
                  You can now return to the homepage and log in using your new password.
                </div>
              )
              : (
                <div>
                  <div className="account-subheading">
                    Complete this form to reset your password.
                  </div>
                  <div className="form-container">
                  
                    <div className="form-section">
                      <label className="label-block" htmlFor="registerPassword">Password</label>
                      <input
                      className="input-block"
                      id="registerPassword"
                      type="password"
                      value={registerPassword}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                      <div className="form-requirements">(Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters)</div>
                    </div>
                    <div className="form-section">
                      <label className="label-block" htmlFor="registerConfirmPassword">Confirm password</label>
                      <input
                      className="input-block"
                      id="registerConfirmPassword"
                      type="password"
                      value={registerConfirmPassword}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                    </div>
                  </div>
                </div>
              )}
            {isSuccess
              ? (
                <div className="form-cta">
                  <a href="/login">Log in now.</a>
                </div>
              )
              : (
                <div className="form-cta">
                  <button
                type="submit"
                onClick={(e) => this.resetPassword(e)}>
                    Reset Password
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
