import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import SimpleModal from '../components/modal/modal';
import Throbber from '../components/throbber';
import StorefrontLanding from '../components/storefront/storefrontLanding';
import CreatorManager from '../managers/Creator';
import HowItWorks from '../components/edrop/howItWorks';
import UtilityManager from '../managers/Utility';
import PreviewBenefits from '../components/edrop/previewBenefits';
import EDropFlexContent from '../components/edrop/edropFlexContent';
import AssetFAQ from '../components/collection/assetFAQ';

const Storefront = () => {
  const [showModal, setShowModal] = useState(true);
  const [dropData, setDropData] = useState(null);
  const [benefits, setBenefits] = useState([]);

  const getBenefits = async (asset) => {
    // get any preview benefits
    const previewBenefits = await UtilityManager.getPreviewUtility(asset.assetId);
    if (previewBenefits && previewBenefits.length > 0) {
      setBenefits(previewBenefits);
    }
  };

  const getDropData = async (dropId) => {
    const dropData = await CreatorManager.getSingleDropV2(dropId);
    if (dropData) {
      console.log('drop data');
      console.log(dropData);
      setDropData(dropData);
      getBenefits(dropData.asset);
    } else {
      toast.error('No drops found for this creator.');
    }
    setShowModal(false);
  };

  const getCreatorData = async () => {
    const creatorData = await CreatorManager.getCreatorData();
    if (creatorData) {
      if (creatorData.assets && creatorData.assets.length > 0) {
        creatorData.assets.forEach((asset) => {
          if (asset.assetId === env.ASSET_ID) {
            getDropData(asset.drops[0].dropId);
          }
        });
      }
    } else {
      toast.error('No drops found for this creator.');
    }
  };

  useEffect(() => {
    // on mount
    // check for drops
    getCreatorData();
  }, []);

  return (
    <div>
      <SimpleModal isOpen={showModal}>
        <Throbber throbberText="Loading storefront..." />
      </SimpleModal>
      {dropData
      && <StorefrontLanding dropData={dropData} />}
      {dropData && dropData.asset
          && <HowItWorks asset={dropData.asset} dropType="storefront" />}
      {benefits && benefits.length > 0
      && <PreviewBenefits asset={dropData.asset} benefits={benefits} />}
      {/* {dropData && dropData.asset
          && <EDropFlexContent asset={dropData.asset} />} */}
      {dropData && dropData.asset
          && (
          <div className="storefront-faq">
            <div className="storefront-faq--title">FAQ</div>
            <AssetFAQ asset={dropData.asset} noHeader />
          </div>
          )}
    </div>
  );
};
export default Storefront;
