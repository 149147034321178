import React, { Component } from 'react';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
    };
  }

  componentDidMount() {
    if (window.location.href.includes('/account/')
      || window.location.href.includes('/login')
      || window.location.href.includes('/register')) {
      this.setState({
        isSticky: true,
      });
    }
  }

  render() {
    const { isSticky } = this.state;
    return (
      <div className={isSticky ? 'footer -fixed' : 'footer'}>
        <div className="footer-inner">
          {env.SMART_CONTRACT
            ? (
              <div>
                View smart contract on
                {' '}
                <a href={env.SMART_CONTRACT} target="_blank" rel="noreferrer">{env.SMART_CONTRACT_CHAIN}</a>
                .
              </div>
            )
            : <div>&nbsp;</div>}
          <div className="footer-inner__right">
            Powered by
            {' '}
            <a href="https://www.ethosnft.com" target="_blank" rel="noreferrer">ethos</a>
            .
            {/* {env.CREATED_BY_NAME
              && (
              <span>
                &nbsp;|&nbsp;Created by
                {' '}
                <a href={env.CREATED_BY_LINK} target="_blank" rel="noreferrer">{env.CREATED_BY_NAME}</a>
                .
              </span>
              )} */}
          </div>
          
        </div>
      </div>
    );
  }
}

export default Footer;
