import Ajax from './Ajax';

/**
* Utility helper for Account functions
*/

class Contests {
  constructor() {
    this.apiUrl = env.SERVER_URL;
  }

  getContest = (token, contestId) => Ajax.get(env.SERVER_URL + 'Contest/byId?contestId=' + contestId, token)
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  enterContest = (token, contestId) => Ajax.post(env.SERVER_URL + 'Contest/enter/' + contestId, null, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })
}

export default new Contests();
