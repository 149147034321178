import React, { Component } from 'react';
import { Outlet, Link } from 'react-router-dom';
import PubSub from 'pubsub-js';
import SlimHero from '../components/hero/slimHero';
import AccountManager from '../managers/Account';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorData: {},
      accountDetails: null,
      currentSection: '',
    };
    PubSub.subscribe('account_updated', this.onPubSubEvent);
  }

  onPubSubEvent = () => {
    this.componentDidMount();
  }

  async componentDidMount() {
    document.body.classList.add('-account-background');
    document.body.style.backgroundImage = 'url(' + env.HERO_BACKGROUND + ')';
    if (AccountManager.isLoggedIn()) {
      // get account info
      const accountData = await AccountManager.get(AccountManager.getToken(), true);
      this.setState({
        accountDetails: accountData,
      });
    } else {
      window.location = '/login';
    }
    this.setState({
      currentSection: window.location.pathname.split('/')[2] || 'collection',
    });
  }

  componentWillUnmount() {
    document.body.classList.remove('-account-background');
    document.body.style.backgroundImage = 'none';
  }

  changeTab = (section) => {
    this.setState({
      currentSection: section,
    });
  }

  render() {
    const { creatorData, accountDetails, currentSection } = this.state;
    return (
      <div className="account-container__outer">
        <div className="account-container__inner">
          <SlimHero creator={creatorData} />
          <div className="account-container__row">
            {accountDetails
              ? (
                <div>
                  <div className="account-intro">
                    {/* <h2 className="account-heading">
                      {'Hello '}
                      {accountDetails.firstName}
                    </h2> */}
                    {/* <p className="account-subheading">
                      Here you can view and make changes to your ethos account.
                    </p> */}
                  </div>
                  <Outlet />
                </div>
              )
              : <div>Loading account data...</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
