import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment-mini';
import PubSub from 'pubsub-js';
import AccountManager from '../../managers/Account';
import CreatorManager from '../../managers/Creator';
import SimpleModal from '../modal/modal';
import LoginForm from '../account/loginForm';
import ConfirmPurchase from '../featuredDrop/confirmPurchase';
import BillingForm from '../account/billingForm';
import { getParameterByName } from '../../managers/Helpers';
import ClaimState from './claimState';

const StorefrontLanding = ({ dropData, asset, drop }) => {
  const [previewData, setPreviewData] = useState(null);
  const [emailAddress, setEmailAddress] = useState('');
  const [claimState, setClaimState] = useState(null);
  const [amountLeft, setAmountLeft] = useState(0);
  const [modalMode, setModelMode] = useState(0);
  const [accountData, setAccountData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [purchaseResult, setPurchaseResult] = useState(null);

  const scrollToContent = () => {
    const content = document.getElementById('startContent');
    if (content) {
      window.scrollTo({
        top: content.offsetTop - 90,
        behavior: 'smooth',
      });
    }
  };

  const setHeroHeight = () => {
    const hero = document.getElementById('storefrontHero');
    if (hero) {
      hero.style.height = window.innerHeight + 'px';
    }
  };

  const handleStickySignup = () => {
    const stickySignup = document.getElementById('stickySignup');
    console.log(stickySignup);
    if (stickySignup && !purchaseResult) {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 100) {
          stickySignup.classList.add('-show');
        } else {
          stickySignup.classList.remove('-show');
        }
      });
    }
  };

  const scrollToTop = (e) => {
    e.preventDefault();
    // const scrollToElement = document.getElementById(element);
    // scrollToElement.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getAccountDetails = async () => {
    const accountData = await AccountManager.get(AccountManager.getToken(), true);
    if (accountData) {
      setAccountData(accountData);
    }
    return accountData;
  };

  const checkBuyStatus = (e, passedAccountData) => {
    let mode = '';
    console.log('=== checkBuyStatus ===');
    console.log(accountData);
    let newAccountData = accountData;
    if (passedAccountData) {
      newAccountData = passedAccountData;
    }
    if (newAccountData && newAccountData.billingInfo && AccountManager.getSelectedCard(newAccountData.paymentMethod)) {
      mode = 'purchase';
    } else if (newAccountData && newAccountData.billingInfo && !newAccountData.paymentMethod) {
      mode = 'card';
    } else {
      mode = 'billing';
    }
    if (e) {
      e.preventDefault();
    }

    setShowModal(true);
    setModelMode(mode);
  };

  const initAccountData = async () => {
    const hasPurchased = localStorage.getItem(env.PURCHASE_COOKIE + dropData.drop.dropId);
    if (AccountManager.isLoggedIn()) {
      await getAccountDetails();
      setIsLoggedIn(true);
      setAmountLeft(dropData.drop.quantityAvailable);
      setPurchaseResult(hasPurchased ? 'success' : null);
    } else {
      setIsLoggedIn(false);
      setAmountLeft(dropData.drop.quantityAvailable);
    }
  };

  const loginFailed = (error) => {
    toast.error(error || 'Oops! Something went wrong. Try again later.');
  };

  const onBillingSuccess = async () => {
    PubSub.publish('account_updated');
    setModelMode('card');
  };

  const onCardSuccess = async () => {
    const accountData = await AccountManager.get(AccountManager.getToken(), true);
    setAccountData(accountData);
    setModelMode('purchase');
  };

  const attemptPurchase = async (amount, paymentId, cartToken) => {
    // const { data, amountLeft } = this.state;
    const parsedDetails = amount;
    parsedDetails.nftName = dropData.asset.name;
    parsedDetails.nftLink = dropData.asset.mediaThumbnailUrl || dropData.asset.mediaUrl;

    const payload = {
      gasFee: parsedDetails.gasFee,
      dropId: dropData.drop.dropId,
      nftName: parsedDetails.nftName,
      // assetVariationId: parsedDetails.assetVariationId,
      cartToken: '',
    };

    if (paymentId) {
      payload.pmId = paymentId;
      payload.paymentToken = paymentId;
    }

    if (cartToken) {
      payload.cartToken = cartToken;
    }

    if (getParameterByName('testAmount', window.location.href)) {
      payload.testAmount = parseInt(getParameterByName('testAmount', window.location.href), 10);
    }

    const purchaseData = await CreatorManager.confirmPurchase(payload, AccountManager.getToken());

    if (purchaseData && purchaseData.success) {
      // update the displayed amount remaining
      const newAmountLeft = amountLeft === 0 ? 0 : amountLeft - 1;
      setShowModal(false);
      setAmountLeft(newAmountLeft);
      setPurchaseResult('success');

      gtag('event', env.CREATOR_SUBDOMAIN + '_purchase', { // eslint-disable-line
        event_category: env.CREATOR_SUBDOMAIN + '_purchase_success',
        event_label: 'User purchased a ' + env.CREATOR_SUBDOMAIN + ' NFT',
      });
      toast.success(purchaseData.message, {
        autoClose: false,
      });
      localStorage.setItem(env.PURCHASE_COOKIE + dropData.drop.dropId, 1);
      // PubSub.publish('drop _updated');
    } else {
      setShowModal(false);
      toast.error(purchaseData.message || 'Oops! Something went wrong while processing the payment. Please try again later.', {
        autoClose: false,
      });
    }
  };

  const loginModal = (e) => {
    e.preventDefault();
    setShowModal(true);
    setModelMode('login');
  };

  const loginSuccess = async () => {
    const updatedAccountDetails = await getAccountDetails();

    setShowModal(false);
    setIsLoggedIn(true);
    toast.success('Log in success.');
    PubSub.publish('account_updated');
    console.log('WTF????');
    console.log(updatedAccountDetails);
    checkBuyStatus(null, updatedAccountDetails);
  };

  const verifyDropState = () => {
    if (moment(dropData.drop.dateClose).unix() < moment().unix()) {
      setClaimState('dropOver');
    }
  };

  useEffect(() => {
    // on mount
    setHeroHeight();
    handleStickySignup();
    window.addEventListener('resize', setHeroHeight);
    initAccountData();
    verifyDropState();
  }, []);

  return (
    <div id="storefrontHero" className="storefront__hero">
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        {modalMode === 'login'
            && <LoginForm onSuccess={() => loginSuccess()} onFail={(error) => loginFailed(error)} onRegisterButton={() => closeModal()} />}
        {modalMode === 'purchase'
            && (
            <div>
              <ConfirmPurchase
                data={dropData}
                onPurchaseAttempt={(amount, paymentId, cartToken) => attemptPurchase(amount, paymentId, cartToken)}
                purchaseResult={purchaseResult}
                accountInfo={accountData} />
            </div>
            )}
        {(modalMode === 'billing' || modalMode === 'card')
            && (
            <div>
              <BillingForm
                onSuccess={() => onBillingSuccess()}
                onCardSuccess={() => onCardSuccess()}
                accountInfo={accountData}
                mode={modalMode} />
            </div>
            )}
        {/*
        {modalMode === 'cardentry' && (
        <PaySafeCardEntry
                total={rawTotal}
                onCardEntry={(token) => this.updateCardToken(token)} />
        )}
            )} */}
      </SimpleModal>
      <div className="storefront__hero--inner">
        {dropData
            && (
            <div className="storefront__hero--content">
              <div className="storefront__hero--asset">
                {dropData.asset.mediaUrl.includes('.mp4') ? (
                  <video autoPlay="autoplay" playsInline loop muted>
                    <source src={dropData.asset.mediaUrl} type="video/mp4" />
                    <track
                      default
                      kind="captions"
                      srcLang="en"
                      src="" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={dropData.asset.mediaUrl} alt={dropData.asset.name} />
                )}
                <div className="storefront__hero--asset__shadow"></div>
              </div>
              <div className="storefront__hero--logo">
                <img src={env.NAV_LOGO} alt={env.SITE_NAME} />
              </div>
              <div className="storefront__hero--title">
                {dropData.asset.name}
              </div>
              {purchaseResult === 'success'
                ? (
                  <div className="featured-drop__description">
                    <p>
                      Congratulations. Your purchase of
                      {' '}
                      <span
                        dangerouslySetInnerHTML={{ __html: dropData.asset.name }}>
                      </span>
                      {' '}
                      was successful.
                    </p>
                    
                    <p>Keep an eye on your inbox. An email receipt is on its way.</p>
                    <p>In the meantime, you can view your NFT in your My Account section.</p>
                    <br />
                    <p className="featured-drop__cta">
                      <Link to="/account/collection" className="button button-storefront">View Your Purchase</Link>
                    </p>
                  </div>
                )
                : (
                  <div>
                    <div
                        className="storefront__hero--description"
                        dangerouslySetInnerHTML={{ __html: dropData.asset.assetHTMLDesc }}>
                    </div>
                    {/* <div className="featured-drop__description -padding-bottom">{env.PURCHASE_INSTRUCTIONS}</div> */}
                    <div className="featured-drop__volume">
                      {amountLeft}
                      {' '}
                      remain.
                    </div>
                    <div className="featured-drop__amount">
                      $
                      {dropData.drop.buyItNow}
                      {' '}
                      USD
                    </div>
                    {claimState
                      ? <ClaimState messageType={claimState} />
                      : (
                        <>
                          {!isLoggedIn
                            ? (
                              <div className="featured-drop__cta">
                                {amountLeft <= 0
                                  ? <a className="button-disabled">Sold Out</a>
                                  : (
                                    <div>
                                      <a href="#" className="button button-storefront" onClick={(e) => loginModal(e)}>Buy Now</a>
                                    </div>
                                  )}
                              </div>
                            )
                            : (
                              <div>
                                {amountLeft <= 0
                                  ? <a className="button-disabled">Sold Out</a>
                                  : (
                                    <div>
                                      <a href="#" className="button button-storefront" onClick={(e) => checkBuyStatus(e)}>Buy Now</a>
                                    </div>
                                  )}
                              </div>
                            )}

                        </>
                      )}
                  </div>
                )}
            </div>
            )}
      </div>
      {dropData && !purchaseResult
      && (
      <div id="stickySignup" className="sticky-signup">
        <div className="sticky-signup__background">
          <div className="sticky-signup__inner">
            <div className="sticky-signup__row">
              <div className="stick-signup__logo">
                <img src={env.NAV_LOGO} alt={env.SITE_NAME} />
              </div>
              <div className="sticky-signup__asset">
                {dropData.asset.mediaUrl.includes('.mp4') ? (
                  <video autoPlay="autoplay" playsInline loop muted>
                    <source src={dropData.asset.mediaUrl} type="video/mp4" />
                    <track
                    default
                    kind="captions"
                    srcLang="en"
                    src="" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={dropData.asset.mediaUrl} alt={dropData.asset.name} />
                )}
                <div>
                  <div className="sticky-signup__title">{dropData.asset.name}</div>
                  {!claimState
                    ? (
                      <div className="sticky-signup__info">
                        <div className="icon">
                          <img src="https://ipfs.ethosnft.com/ethos/v2/ui/icon-check.png" alt="Warning" />
                        </div>
                        Available to purchase!
                      </div>
                    )
                    : (
                      <div className="sticky-signup__info">
                        <div className="icon">
                          <img src="https://ipfs.ethosnft.com/ethos/v2/ui/icon-x.svg" alt="Warning" />
                        </div>
                        Unavailable
                      </div>
                    )}
                </div>
              </div>
            </div>

            {!claimState
            && (
            <div className="sticky-capture">
              {!isLoggedIn
                ? (
                  <div className="featured-drop__cta">
                    {amountLeft <= 0
                      ? <a className="button-disabled">Sold Out</a>
                      : (
                        <div>
                          <a href="#" className="button button-edrop" onClick={(e) => loginModal(e)}>Buy Now</a>
                        </div>
                      )}
                  </div>
                )
                : (
                  <div>
                    {amountLeft <= 0
                      ? <a className="button-disabled">Sold Out</a>
                      : (
                        <div>
                          <a href="#" className="button button-edrop" onClick={(e) => checkBuyStatus(e)}>Buy Now</a>
                        </div>
                      )}
                  </div>
                )}
            </div>
            )}
          </div>
        </div>
      </div>
      )}
      <div className="hero--more-arrow" onClick={() => scrollToContent()}>
        <div className="more-arrow-bounce">
          Learn more
          <img src="https://ipfs.ethosnft.com/ethos/v2/ui/icon-down-arrow.svg" alt="Warning" />
        </div>
      </div>
    </div>
  );
};
export default StorefrontLanding;
